import React from 'react'
import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'

import Seo from 'components/SEO'
import PageContent from 'components/Shared/_PageContent'
import Title from 'components/Shared/Title'
import JoinNav from 'components/Public/JoinNav'

import styled from 'styled-components'
const Wrapper = styled.dl`
  width: clamp(18ch, 75ch, 100%);
  margin-bottom: calc(var(--margin-default) * 2.5);
  p {
    --text-align: left;
  }

  .file {
    margin-bottom: 2rem;
  }
`

const FAQ = ({ data, location }) => {
  const {
    faq: { body },
    seo,
  } = data
  const { title, description } = seo.frontmatter.faq

  return (
    <PageContent>
      <Seo title={title} description={description} />
      <JoinNav location={location} />
      <Title>常見問題</Title>
      <Wrapper>
        <MDXRenderer>{body}</MDXRenderer>
      </Wrapper>
    </PageContent>
  )
}

export default FAQ

export const query = graphql`
  {
    faq: mdx(frontmatter: { slug: { eq: "faq" } }) {
      body
    }

    seo: mdx(frontmatter: { type: { eq: "seo" } }) {
      frontmatter {
        faq {
          title
          description
        }
      }
    }
  }
`
